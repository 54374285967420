window.SETTINGS = (() => {

  const styleTest = {
    title: 'app.short.title.test',
    headerclass: 'test-env',
    icon: ''
  }

  const styleProd = {
    title: 'app.short.title.production',
    headerclass: '',
    icon: ''
  }

  const stylePreview = {
    title: 'app.short.title.preview',
    headerclass: 'preview-env',
    icon: ''
  }

  const styleProdBeta = { ...stylePreview, title: 'app.short.title.production_beta'}
  const styleProdOld = { ...stylePreview, title: 'app.short.title.prod_old'}

  let realm = (host, domain, useSSl, googleAnalyticsId, releasedLangs = [], style = styleTest) => ({
    URL: (useSSl ? "https" : "http") + "://" + host + "/api/",
    TRANSLATE_URL: (useSSl ? "https" : "http") +"://" + host + "/api-translate/translate/",
    // IMPORT_URL: "http://localhost:8080/",
    IMPORT_URL: (useSSl ? "https" : "http") +"://" + host + "/api-sync/",
    BANNERS: (useSSl ? "https" : "http") + "://" + domain + "/banners/",
    DOMAIN: (useSSl ? "https" : "http") + "://" + domain + "/",
    WS: (useSSl ? "wss" : "ws") + "://" + host + "/api/ws",
    SETTINGS: {
      googleAnalyticsId: googleAnalyticsId
    },
    RELEASED_LANGUAGES: releasedLangs,
    STYLE: style
  })

  return {
    realms: {
      LOCAL: realm("localhost:8080", "localhost:8081", false, "UA-129423775-1", ['en', 'fr', 'zh', 'es', 'pt-BR', 'it', 'hi', 'de']),
      TEST: realm("api.lenav.s1.extech.by", "lenav.s1.extech.by", true, "UA-129423775-1", ['en', 'fr', 'zh', 'es', 'pt-BR', 'it', 'hi', 'de']),
      production: realm("clients.lodgingeconometrics.com", "clients.lodgingeconometrics.com", true, "UA-123709730-1", ['en', 'fr', 'zh', 'es', 'pt-BR', 'it', 'hi', 'de'], styleProd),
      production2: realm("lenav2.extech.by", "lenav2.extech.by", true, "UA-123709730-1", ['en', 'fr', 'zh', 'es', 'pt-BR', 'it', 'hi', 'de'], styleProdOld),
      preview: realm("lenav2.extech.by", "lenav2.extech.by", true, "UA-123709730-1", ['en', 'fr', 'zh', 'es', 'pt-BR', 'it', 'hi', 'de'], styleProdBeta),
    }
  }
})();